export const breakpoint = {
  max: {
    xs: '575.98px',
    sm: '767.98px',
    md: '991.98px',
    lg: '1199.98px',
  },
  min: {
    xs: '576px',
    sm: '768px',
    md: '992px',
    lg: '1200px',
  },
};

export const phone = `@media screen and (max-width: ${breakpoint.max.sm})`;
export const tablet = `@media screen and (min-width: ${breakpoint.min.sm}) and (max-width: ${breakpoint.max.md})`;
export const laptop = `@media screen and (min-width: ${breakpoint.min.md})`;
