import { createGlobalStyle } from 'styled-components';
import styledNormalize from 'styled-normalize';

const GlobalStyles = createGlobalStyle`
  ${styledNormalize}

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    font-size: 18px;
  }

  body {
    background-color: #ffffff;
    font-size: 18px;
    font-family: "Nunito Sans", Arial, sans-serif;
  }

  html, body {
    overflow-y: visible;
    overflow-x: hidden;
  }

  a {
    text-decoration: none;
  }

  img {
    max-width: 100%;
  }
`;

export const Background = createGlobalStyle`
  body {
    background-color: #fafafa;
  }
`;

export default GlobalStyles;
