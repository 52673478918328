const theme = {
  font: {
    primary: "'Nunito Sans', sans-serif",
    secondary: "Montserrat, sans-serif",
    code: "'Space Mono', monospace",
  },
  fontSize: {
    h1: '62px',
    h2: '48px',
    h3: '34px',
    p: {
      small: '16px',
      medium: '20px',
      large: '30px',
      placeholder: '20px',
    },
    code: '20px',
  },
  fontWeight: {
    h1: 900,
    h2: 900,
    h3: 700,
    code: 700,
  },
  lineHeight: {
    h1: '106%',
    h2: '106%',
    h3: '120%',
    p: {
      small: '160%',
      medium: '154%',
      large: '130%',
      placeholder: '154%',
    },
    code: '100%',
  },
  sm: {
    fontSize: {
      h1: '44px',
      h2: '36px',
      h3: '30px',
      p: {
        small: '14px',
        large: '24px',
      },
    },
    lineHeight: {
      h1: '110%',
      h2: '120%',
      h3: '125%',
      p: {
        large: '154%',
      },
    },
  },
  color: {
    cyan: '#58d1bd',
    greenCyan: '#00e97b',
    teal: '#26a192',
    blue: '#3770ff',
    navy: '#3936ab',
    lightTeal: '#58d1bd',
    sky: '#81a2ef',
    lightSky: '#edf1f9',
    sand: '#eeb27b',
    beige: '#f0eacf',
    silver: '#fafafa',
    mustard: '#ffd74a',
    grey: '#273339',
    lightGrey: '#616b70',
    lightestGrey: '#a6a9ab',
    white: '#ffffff',
    black: '#000000',
  },
  shadow: {
    largeImage: '0px 5px 16px rgba(0, 0, 0, 0.1)',
  },
};

export default theme;
