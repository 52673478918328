import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import React from 'react';

import GlobalStyles, { Background } from '@components/_globalStyles';
import SEO from '@components/SEO';
import theme from '@components/Theme';

const Layout = (props) => {
  const { title, description, image, pathname, white, children } = props;

  return (
    <>
      <SEO title={title} image={image} description={description} pathname={pathname} />

      <GlobalStyles />
      {!white && <Background />}

      <ThemeProvider theme={theme}>
        <>{children}</>
      </ThemeProvider>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  white: PropTypes.bool,
};

Layout.defaultProps = {
  white: false,
};

export default React.memo(Layout);
