import styled, { css } from 'styled-components';

import { breakpoint } from '@constants';
import theme from '@components/Theme';

export const Container = styled.div`
  box-sizing: border-box;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;

  @media (min-width: ${breakpoint.min.xs}) {
    max-width: 540px;
  }

  @media (min-width: ${breakpoint.min.sm}) {
    max-width: 720px;
  }

  @media (min-width: ${breakpoint.min.md}) {
    max-width: 960px;
  }

  @media (min-width: ${breakpoint.min.lg}) {
    max-width: 1200px;
  }

  ${(props) =>
    props.fluid &&
    css`
      max-width: inherit !important;
    `};
`;

export const Section = styled.section`
  box-sizing: border-box;
  padding: 5.6rem 4rem;
  background-color: ${(props) => theme.color[props.bgColor] || 'transparent'};

  @media (max-width: ${breakpoint.max.sm}) {
    padding: 5.6rem 2rem;    
  }

  // &#community {
  //   @media (max-width: ${breakpoint.max.md}) {
  //     background-color: transparent;
  //   }
  // }

  ${(props) =>
    props.light &&
    css`
      background-color: #fffff;
    `}

  ${(props) =>
    props.fluid &&
    css`
      padding: 6rem 0;
    `}
  
  ${(props) =>
    props.grid &&
    css`
      padding: 4rem 0 !important;
    `}
`;

export const Blockquote = styled.blockquote`
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 154%;
  color: #273339;
  margin: 0;
  position: relative;
  padding-left: 24px;

  &::before {
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 6px;
    height: 100%;
    background-color: ${(props) => theme.color[props.color || 'black']};
    border-radius: 3px;
  }
`;

// Underline using psuedo element on element
export const Underline = styled.span`
  position: relative;

  ::after {
    content: '';
    position: absolute;
    display: block;
    height: 8px;
    bottom: 16px;
    left: 0;
    width: 100%;
    background-color: ${(props) => theme.color[props.color] || theme.color.greenCyan};
    z-index: -1;
  }
`;

export const Button = styled.button`
  align-items: center;
  background-color: #ebeeff;
  border-radius: 8px;
  border: #ebeeff;
  color: #3770ff;
  display: flex;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 20px;
  font-weight: 600;
  height: 56px;
  justify-content: center;
  margin: 0px;
  outline: none;
  padding: 0px 32px;
  transition: all 0.1s ease-in 0s;
  white-space: nowrap;
  width: ${(props) => (props.stretch ? '100%' : 'auto')};
  cursor: pointer;

  &:hover {
    background-color: #162692;
    color: #ffffff;
  }

  ${(props) =>
    props.appearance === 'primary' &&
    css`
      background-color: #3770ff;
      color: #ffffff;
    `};
`;

export const Clickable = styled.div`
  position: absolute;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  top: 0;
  right: 0;
`;

Clickable.Area = styled.div`
  position: absolute;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  top: ${(props) => props.top};
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};

  ${(props) =>
    props.arrow === 'left' &&
    css`
      cursor: url('/arrow-left.png'), w-resize;
    `};

  ${(props) =>
    props.arrow === 'right' &&
    css`
      cursor: url('/arrow-right.png'), e-resize;
    `};
`;

export const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadow.largeImage};
`;
