import styled, { css } from 'styled-components';

import theme from '@components/Theme';
import { breakpoint } from '@constants';

const heading = css`
  font-family: ${theme.font.primary};
  color: #273339;
  margin: 0;
`;

export const H1 = styled.h1`
  ${heading};
  font-weight: ${theme.fontWeight.h1};
  font-size: ${theme.fontSize.h1};
  line-height: ${theme.lineHeight.h1};
  color: ${(props) => theme.color[props.color]};

  @media (max-width: ${breakpoint.max.xs}) {
    font-size: ${theme.sm.fontSize.h1};
    line-height: ${theme.sm.lineHeight.h1};
  }
`;

export const H2 = styled.h2`
  ${heading};
  font-weight: ${theme.fontWeight.h2};
  font-size: ${theme.fontSize.h2};
  line-height: ${theme.lineHeight.h2};
  color: ${(props) => theme.color[props.color]};

  @media (max-width: ${breakpoint.max.xs}) {
    font-size: ${theme.sm.fontSize.h2};
    line-height: ${theme.sm.lineHeight.h2};
  }
`;

export const H3 = styled.h3`
  ${heading};
  font-weight: ${theme.fontWeight.h3};
  font-size: ${theme.fontSize.h3};
  line-height: ${theme.lineHeight.h3};
  color: ${(props) => theme.color[props.color]};

  @media (max-width: ${breakpoint.max.xs}) {
    font-size: ${theme.sm.fontSize.h3};
    line-height: ${theme.sm.lineHeight.h3};
  }
`;

const paragraph = css`
  font-family: ${theme.font.primary};
  font-size: ${theme.fontSize.p.medium};
  line-height: ${theme.lineHeight.p.medium};
  color: #273339;
  margin: 0;
`;

export const P = styled.p`
  ${paragraph};
  color: ${(props) => theme.color[props.color]};

  ${(props) =>
    props.size === 'small' &&
    css`
      font-size: ${theme.fontSize.p.small};
      line-height: ${theme.lineHeight.p.small};

      @media (max-width: ${breakpoint.max.xs}) {
        font-size: ${theme.sm.fontSize.p.small};
      }
    `};

  ${(props) =>
    props.size === 'large' &&
    css`
      font-size: ${theme.fontSize.p.large};
      line-height: ${theme.lineHeight.p.large};

      @media (max-width: ${breakpoint.max.xs}) {
        font-size: ${theme.sm.fontSize.p.large};
        line-height: ${theme.sm.lineHeight.p.large};
      }
    `};

  ${(props) =>
    props.type === 'placeholder' &&
    css`
      font-size: ${theme.fontSize.p.placeholder};
      line-height: ${theme.lineHeight.p.placeholder};
      color: #a6a9ab;
    `};
`;

const code = css`
  font-family: ${theme.font.code};
  font-size: ${theme.fontSize.code};
  line-height: ${theme.lineHeight.code};
  font-weight: ${theme.fontWeight.code};
  color: ${(props) => theme.color[props.color]};
  margin: 0;
`;

export const Code = styled.p`
  ${code};
  color: ${(props) => theme.color[props.color]};
`;

export const A = styled.a`
  cursor: pointer;
  font-family: ${theme.font.primary};
  font-size: ${theme.fontSize.p.medium};
  line-height: ${theme.lineHeight.p.medium};
  font-weight: 400;
  color: #616b70;

  ${(props) =>
    props.size === 'small' &&
    css`
      font-size: ${theme.fontSize.p.small};
      line-height: ${theme.lineHeight.p.small};

      @media (max-width: ${breakpoint.max.xs}) {
        font-size: ${theme.sm.fontSize.p.small};
      }
    `};

  ${(props) =>
    props.size === 'large' &&
    css`
      font-size: ${theme.fontSize.p.large};
      line-height: ${theme.lineHeight.p.large};

      @media (max-width: ${breakpoint.max.xs}) {
        font-size: ${theme.sm.fontSize.p.large};
        line-height: ${theme.sm.lineHeight.p.large};
      }
    `};

  :hover {
    color: #273339;
  }

  ${(props) =>
    props.appearance === 'link' &&
    css`
      font-weight: 700;
      color: #3770ff;

      :hover {
        color: #2f4ae4;
      }
    `};

  ${(props) =>
    props.appearance === 'muted' &&
    css`
      color: ${theme.color.lightGrey};

      :hover {
        color: ${theme.color.grey};
      }
    `};
`;
