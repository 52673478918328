import { Link } from 'gatsby';
import React from 'react';

import logoSrc from '+static/logo.svg';

const devfolioBrandAssetsLink = 'https://github.com/devfolioco/brand-assets';

const Logo = ({ isLoggedIn = false }) => {
  const logoRef = React.useRef(null);

  /**
   * This adds an event listener on the span that wraps around the logo.
   *
   * This event listener listens for right clicks (context menu) & redirects the user to
   * the Devfolio brand assets page when a right click event is triggered.
   */
  React.useEffect(() => {
    const onLogoRightClick = (event) => {
      event.preventDefault();
      window.open(devfolioBrandAssetsLink, '_blank', 'noreferrer');
    };
    const logo = logoRef.current;
    if (logo) {
      logo.addEventListener('contextmenu', onLogoRightClick);
    }
    return () => {
      if (logo) {
        logo.removeEventListener('contextmenu', onLogoRightClick);
      }
    };
  }, []);

  return (
    <Link to={isLoggedIn ? '/home' : '/'} aria-label="Homepage">
      <img
        height="34px"
        width="122px"
        src={logoSrc}
        alt="Devfolio logo"
        ref={logoRef}
      />
    </Link>
  );
};

export default Logo;
